import * as React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ContentBoxList = ({ list }) => {
  return (
    <div className="container max-w-3xl mx-auto">
      <dl className="space-y-6 divide-y divide-gray-200">
        {list.map(item => (
          <Disclosure as="div" key={item.title} className="pt-6">
            {({ open }) => (
              <>
                <dt className="text-lg">
                  <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                    <span className="font-medium text-gray-900">
                      {item.title}
                    </span>
                    <span className="ml-6 h-7 flex items-center">
                      <ChevronDownIcon
                        className={classNames(
                          open ? '-rotate-180' : 'rotate-0',
                          'h-6 w-6 transform'
                        )}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <div
                    className="font-light text-left prose"
                    dangerouslySetInnerHTML={{
                      __html: item.description?.childMarkdownRemark.html,
                    }}
                  ></div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>
  )
}

export default ContentBoxList
