import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import { useTranslation } from 'react-i18next'
import { FAQ } from '../components/faq'
import { GatsbyImage } from 'gatsby-plugin-image'

const FAQPage = ({ data }) => {
  const { t } = useTranslation()
  const updatedLinks = data.links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )
  const {title, faqBlock, seoMetaTags} = data.faq

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={seoMetaTags}>
      <Container color="bg-beige">
        <SectionTitle title={title} centered={true} main={true} />
        <FAQ faqItems={faqBlock} />
      </Container>
    </Layout>
  )
}

export default FAQPage

export const faqDetailQuery = graphql`
  query ($id: String!, $originalId: String!) {
    faq: datoCmsFaq(id: { eq: $id }) {
      title
      faqBlock {
        title: question
        description: answerNode {
          childMarkdownRemark {
            html
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    links: allDatoCmsFaq(filter: { originalId: { eq: $originalId } }) {
      nodes {
        slug
        locale
      }
    }
  }
`
