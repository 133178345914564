import * as React from 'react'
import { Container } from './container'
import ContentBoxList from './infobox/content-box-list'
import { SectionTitle } from './section-title'

export function FAQ({ faqItems }) {
  return (
    <Container>
      <SectionTitle title="FAQ" centered={true} />
      <ContentBoxList list={faqItems} />
    </Container>
  )
}
